<template>
  <div class="app-header mt-1">
    <img alt="Vue logo" src="./assets/logo.png">
    <hr />
  </div>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body {
  margin: 0;
}

.app-header {
  background-color:white;
  margin: 0;
  text-align: center;
  margin: auto;
}
.app-header img {
  width: 50%;
  max-width: 168px;
}
.app-header hr {
  color: #BB8F49;
  margin-right: 20%;
  margin-left: 20%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
