<template>
  <div class="jumbotron">
    <h1 class="display-4">Merci d'avoir participé</h1>
    <p class="lead" v-if="! conponRequested">Nous sommes heureux de vous offrir un <a href="#" @click="conponRequested=true">coupon de réduction</a> valable sur la boutique en ligne MaxVauché.</p>
    <div v-if="couponSent" class="alert alert-primary" role="alert">
      Votre coupon vous attend dans votre boite email !
    </div>
    <div class="lead" v-else-if="conponRequested">
      <div class="form-group">
        <label for="exampleInputEmail1">Saisissez votre adresse pour recevoir ce coupon de réduction</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email@domain.fr" v-model="email">
        <small id="emailHelp" class="form-text text-muted">Nous ne partagerons pas votre email avec d'autres compagnies</small>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="newsletter">
        <label class="form-check-label" for="exampleCheck1">Recevoir les bons plans et recettes MaxVauché</label>
      </div>
      <div class="final_form">
        <button type="submit"
                @click="registerCoupon()"
                :disabled="! isValidEmail"
                class="btn"
                :class="{'btn-primary': isValidEmail }">
          Recevoir
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    score: Number,
  },
  ///////////////////////////////////////////////////////////////////////
  data() {
    return {
      email: '',
      newsletter: false,
      couponSent: false,
      conponRequested: false
    };
  },
  ///////////////////////////////////////////////////////////////////////
  methods: {
    registerCoupon() {
      console.log("email: " + this.email + " registered: "+ this.newsletter)
      this.couponSent = true
    }
  },
  ///////////////////////////////////////////////////////////////////////
  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    }
  }
};
</script>

<style scoped>
ul.list-group {
  /* padding: 0px 5px 0px 5px; */
  margin-left: 25%;
  margin-right: 25%;
}

</style>