<template>
  <div class="card mt-4">
    <img v-if="question.illustration" 
        class="card-img-top illustration" 
        :src="question.illustration" 
        alt="illustration" />
    <div class="card-body">
      <h3 class="card-title">{{ question.question }}</h3>
      <Transition name="bounce">
        <div v-if="waitingForAnswer()" class="card-text">
          <ul class="list-group">
            <li
              v-for="(option, index) in question.choices"
              :key="index"
              class="list-group-item list-group-item-action"
              :class="choiceColor(option)"
              @click="checkAnswer(option)"
            >
              {{ option }}
            </li>
          </ul>
          <button class="btn btn-mv mt-4" @click="validateAnswer()" :disabled="this.selectedOption.length == 0">Valider</button>
        </div>
      </Transition>
      <div v-if="! waitingForAnswer()">
        <div v-if="question.choices.length">
          <div v-if="this.isCorrect(selectedOption)" class="alert alert-success" role="alert"><h2>Gagné !</h2></div>
          <div v-else class="alert alert-warning" role="alert"><h2>Perdu !</h2></div>
        </div>
        <div :class="[question.choices.length ? 'card-header' : '']">
          {{ question.explaination }}
        </div>
        <button class="btn btn-mv mt-4" @click="nextQuestion()">Suivant</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
  },
  ///////////////////////////////////////////////////////////////////////
  data() {
    return {
      selectedOption: '',
      optionValidated: false
    };
  },
  ///////////////////////////////////////////////////////////////////////
  methods: {
    isCorrect(option) {
      return option === this.question.correctAnswer || this.question.correctAnswer === '*'
    },
    waitingForAnswer() {
      return ((this.question.choices.length) && ((! this.optionValidated) || (this.selectedOption.length == 0)))
    },
    checkAnswer(option) {
      if (!this.optionValidated) {
        this.selectedOption = option
      }
    },
    validateAnswer() {
      this.optionValidated = true
    },
    nextQuestion() {
      this.$emit("answer", this.isCorrect(this.selectedOption))
      this.selectedOption = ''
      this.optionValidated = false
    },
    choiceColor(option) {
      return (this.selectedOption == option) ? 'quiz-selected-answer' : ''
      //if (this.isCorrect(option))
      //  return 'bg-success'
      //return 'bg-danger'
    }
  }
};
</script>

<style scoped>
ul.list-group {
  /* padding: 0px 5px 0px 5px; */
  margin-left: 25%;
  margin-right: 25%;
}

li.quiz-selected-answer {
  background-color: #BB8F49;
  color: white;
  font-weight: bolder;
}

.btn-mv {
  background-color: #BB8F49;
  color: white;
}

.bounce-enter-active {
  animation: none;
}
.bounce-leave-active {
  animation: bounce-out 0.3s reverse;
}

@keyframes bounce-out {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
