<template>
  <div class="container text-center">
    <h1 class="quiz-title mt-3  " @click="Retry">{{ title }}</h1>
    <div class="quiz-item" v-if="currentQuestion">
      <quiz-item
        :question="currentQuestion"
        @answer="handleAnswer"
      ></quiz-item>
    </div>
    <div v-else>
      <div class="border m-4">
        <h4 class="mt-4">votre score: {{ correctAnswers }} / {{ questionsList?.length }}</h4>
        <p>{{ evaluation }}</p>
      </div>
      <MvCoupon v-if="couponActive"/>
    </div>
  </div>
</template>

<script>
import QuizItem from "./QuizItem.vue"
import MvCoupon from './MvCoupon.vue'
import axios from 'axios'
//import quizData from "@/assets/quiz-data.json"

export default {
  components: {
    QuizItem,
    MvCoupon
  },
  ///////////////////////////////////////////////////////////////////////
  props: {
    quizName: String
  },
  ///////////////////////////////////////////////////////////////////////
  data() {
    return {
      currentQuestionIndex: 0,
      correctAnswers: 0,
      questionsList: [],
      title: '',
      conclusions: {},
      couponActive: false
    };
  },
  ///////////////////////////////////////////////////////////////////////
  computed: {
    currentQuestion() {
      if (! this.questionsList)
        return null
      return this.questionsList[this.currentQuestionIndex];
    },
    evaluation() {
      if (! this.questionsList)
        return ''
      if (this.correctAnswers == this.questionsList.length)
        return this.conclusions.allgood
      else if (this.correctAnswers > (this.questionsList.length / 2))
        return this.conclusions.good
      else if (this.correctAnswers > 1)
        return this.conclusions.some
      return this.conclusions.none
    }
  },
  methods: {
    Retry() {
      this.currentQuestionIndex = 0
      this.correctAnswers = 0
      this.saveProgress();
    },
    handleAnswer(isCorrect) {
      if (isCorrect) {
        this.correctAnswers++;
      }
      this.currentQuestionIndex++;
      this.saveProgress();
    },
    saveProgress() {
      localStorage.setItem("quizProgress", this.currentQuestionIndex);
      localStorage.setItem("quizAnswers", this.correctAnswers);
    },
    loadQuizByName() {
      // Load questions from the JSON file
      axios
        .get('/quiz-data.json')
        .then((response) => {
          let quizData = response.data.quiz
          this.couponActive = response.data.couponActive
          for (let quizId = 0; quizId < quizData.length; quizId++)
          {
            if (quizData[quizId].link === this.quizName)
            {
              this.questionsList = quizData[quizId].questions
              this.title = quizData[quizId].description
              this.conclusions = quizData[quizId].conclusions
              break
            }
          }
          if (! this.questionsList.length)
          {
            this.questionsList = quizData[0].questions;
            this.title = quizData[0].description
            this.conclusions = quizData[0].conclusions
          }

          const savedProgress = localStorage.getItem("quizProgress");
          if (savedProgress) {
            this.currentQuestionIndex = parseInt(savedProgress);
          }
          const savedScore = localStorage.getItem("quizAnswers");
          if (savedScore) {
            this.correctAnswers = parseInt(savedScore);
          }
        })
    }
  },
  mounted() {
    this.loadQuizByName()
  },
  watch: {
    quizName: {
        handler: function(newVal, OldVal) {
          console.log('quiz from "' + OldVal + '" to "' + newVal + '"')
          this.questionsList = []
          this.loadQuizByName()
      }
    }
  }
};
</script>

<style scoped>

@font-face {
  font-family: "Yeseva One";
  font-display: auto;
  src: url("@/assets/YesevaOne-Regular.ttf");
}
.quiz-title {
  color: #BB8F49;
  font-family: "Yeseva One";
}
.quiz-item {
  /* max-width: 400px; */
  /* flex: auto; */
}
</style>
