import { createRouter, createWebHashHistory } from 'vue-router'
import MvQuiz from "../components/MvQuiz.vue"

const routes = [
  {
    path: '/:quizName(.*)',
    name: 'MvQuiz',
    component: MvQuiz,
    props: true,
  }
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})

// router.beforeEach((to, from) => {
//   console.log('going from ' + from + ' to ' + to)
//   return true
// })

export default router
